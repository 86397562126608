<template>
  <div class="dashboard-v2 artist-container">
    <h1>Song reach</h1>
  </div>
</template>
<script>
export default {
  name: "Dashboard",
};
</script>
<style lang="scss" scoped>
.artist-head-title {
  font-weight: 700;
  color: #33647f;
  font-size: 20px;
}
.artist-name {
  color: #33647f;
}
.box-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>
